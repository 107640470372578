import { clean, cleanDiacritics, underscored } from "underscore.string";
import Color from "color";

export function normalizeString(string) {
  return underscored(cleanDiacritics(clean(string)));
}

export function isURL(str) {
  if (!str) {
    return false;
  }
  const urlRegex = "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
  const url = new RegExp(urlRegex, "i");
  return str.length < 2083 && url.test(str);
}

export function removeQuotes(string) {
  return string ? string.replace(/['"]+/g, "") : null;
}

export function isEmptyString(string) {
  if (!string) {
    return true;
  }
  return /^\s*$/.test(string);
}

export function convertColorToHexa(color) {
  return Color(color).hex();
}

export function removeTags(string) {
  if (string === null || string === "") return "";

  return string.replace(/<\/?[^>]+(>|$)/gi, "");
}

export function generatePathSlug(str) {
  if (str === "") return "";

  const normalizeSlug = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  return normalizeSlug.replace(/[^a-zA-Z0-9]+/g, "-");
}

export function getSubstringAfterPrefix(str, prefix) {
  return str.startsWith(prefix) ? str.slice(prefix.length) : null;
}

export function extractStringBetween(str, prefix, suffix) {
  const start = str.indexOf(prefix) + prefix.length;
  const end = str.indexOf(suffix, start);
  return start !== -1 && end !== -1 ? str.substring(start, end) : null;
}
